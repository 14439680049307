import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Layout from '../components/Layout';
import ContentWrapper from '../components/ContentWrapper';
import SEO from '../components/seo';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <ContentWrapper>
      <h1>About Outside Simulator</h1>
      <p>
        Hi there. My name is Andre Vitorio and I'm the creator of
        Outside&nbsp;Simulator.
      </p>
      <p>
        We are currently going through difficult times. A lot of us are stuck at
        home and can't wait to get out. I've created Outside Simulator as a way
        to help others cope with not being able to go out.
      </p>
      <p>
        You can contact me at
        {' '}
        <OutboundLink target="_blank" nofollow href="https://AndreVitorio.com">
          https://AndreVitorio.com
        </OutboundLink>
        . If you like this project you can
        {' '}
        <a target="_blank" href="https://www.buymeacoffee.com/andrevitorio" rel="noopener noreferrer">
          buy me a coffee
        </a>
        {' '}
        or visit and subscribe/follow the content creators below.
      </p>
      <p>
        Here is a list of resources used to create this website.
        <br />
        I wouldd like to thank all of the creators that have helped me
        build this.
      </p>
      <h2>Design Inspiration</h2>
      <ul>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://dribbble.com/shots/11012652--Timed-Cards-Opening"
          >
            Timed Cards Opening by @★ Giulio Cuscito ★
          </OutboundLink>
        </li>
      </ul>
      <h2>Videos</h2>
      <ul>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=MXwBUPf0YyM">
            Edinburgh
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=478TeAxm12g">
            Autumn Forest
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=SYIEFsP8Hy0">
            Wailea
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=vaXLdF6sRhg">
            Nice
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=vpgd9UbCzpU">
            Rio de Janeiro
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=ot3tgReW8hw">
            Monaco
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=G-v-UKEJ4E4">
            Paris
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=E07rTPgIvn0      ">
            Amsterdam
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=EsFheWkimsU">
            Rome
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=6qGiXY1SB68">
            Tokyo
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=axqSGXU5qIw">
            Ibiza
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=uZocEYAyUSs">
            Pulau Ujong
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=fGxbtg1ytJo">
            Zurich
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=kGM6NsRN2Kg">
            Nature
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=dUYwG3H7VL8">
            Krabi
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=ET0ucdMIyo4">
            Los Angeles
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=Yrd7EoMF31I">
            San Francisco
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=vsI_pKNcgeQ">
            London
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=BOa0zQBRs_M">
            New York
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.youtube.com/watch?v=4VUoCFc_2Uo">
            Moscow
          </OutboundLink>
        </li>
      </ul>
      <h2>Images</h2>
      <ul>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/art_3m"
          >
            Autumn Forest by @art_3m
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/davidbreaker"
          >
            Edinburgh by @davidbreaker
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/lastlystudios"
          >
            Hawaii by @lastlystudios
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/Gant_Images"
          >
            Amsterdam by @Gant_Images
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/cuadlens"
          >
            Rome by @cuadlens
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/tydegidts"
          >
            Monaco by @tydegidts
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/next_voyage"
          >
            Paris by @next_voyage
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/apasaric_photography"
          >
            Tokyo at Night by @apasaric_photography
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://www.flickr.com/photos/deepstereo/"
          >
            Ibiza by @deepstereo
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/danuadhitya"
          >
            Singapore by @danuadhitya
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://www.instagram.com/Suissounet/"
          >
            Zurich by @Suissounet
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/kaboompics"
          >
            Woods by @kaboompics
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/lisafotios"
          >
            Coffee Shop by @lisafotios
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/leon.warnking"
          >
            Subway by @leon.warnking
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/david__geib"
          >
            Oxford Street by @david__geib
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/ryanmillier"
          >
            NYC Rain by @ryanmillier
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://www.flickr.com/photos/jodastephen"
          >
            Nice by @jodastephen
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            target="_blank"
            nofollow
            href="https://instagram.com/stanley_laura"
          >
            Concert by @stanley_laura
          </OutboundLink>
        </li>
      </ul>
      <h2>Icons</h2>
      <ul>
        <li>
          Made by
          {' '}
          <OutboundLink
            target="_blank"
            nofollow
            href="https://www.flaticon.com/authors/freepik"
            title="Freepik"
          >
            Freepik
          </OutboundLink>
          {' '}
          from
          <OutboundLink
            target="_blank"
            nofollow
            href="https://www.flaticon.com/"
            title="Flaticon"
          >
            www.flaticon.com
          </OutboundLink>
        </li>
      </ul>
    </ContentWrapper>
  </Layout>
);

export default AboutPage;
