import styled from "styled-components"

export const Container = styled.div`
  display: block;
  max-width: 600px;
  width: 100%;
  margin: 50px auto;
  background: #000000a6;
  padding: 40px;
  border-radius: 4px;

  a {
    font-family: "Roboto", sans-serif;
    text-decoration: underline;
  }

  ul {
    list-style: square;
    margin-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }
`
